<template>
  <router-link :to="`/${data.id}`" class="event-card">
    <div v-if="!past" class="event-card__date">{{ getTime(data.start) }}</div>
    <img
      :src="imageSrc"
      :alt="`${data.name} image`"
      class="event-card__image"
    />
    <div class="event-card__content">
      <div>
        <h3>
          {{ data.name }}
        </h3>
      </div>
    </div>
  </router-link>
</template>

<script>
import { shortenText } from "@/assets/js/utils";

export default {
  name: "EventCard",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    past: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    imageSrc() {
      return this.data.background
        ? this.data.background
        : require("@/assets/img/main_background.jpeg");
    },
  },
  methods: {
    shortenText,
    getTime(dateString) {
      const startDate = new Date(dateString);
      return startDate.toLocaleString("en-US", {
        timeZone: this.data.time_zone,
        month: "short",
        day: "2-digit",
        year: "numeric",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.event-card {
  cursor: pointer;
  position: relative;
  width: 340px;
  margin-right: 32px;
  margin-bottom: 32px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: $black;

  h3 {
    font-size: 32px;
    line-height: 1.2em;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 402px;
    height: 320px;
  }

  &__date {
    color: $black;
    position: absolute;
    top: 16px;
    left: 24px;
    padding: 12px 24px;
    background-color: #fff;
    border-radius: 10px;
    z-index: 2;
    font-size: 16px;
  }

  &__image {
    width: 100%;
    height: 320px;
    object-fit: cover;
    object-position: center;
    display: block;
  }

  &__content {
    padding: 32px 15px 32px 24px;
    letter-spacing: 0.05em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
