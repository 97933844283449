import { createStore } from "vuex";
import auth from "./modules/auth";
import crud from "./modules/crud";
import ui from "./modules/ui";
import tickets from "./modules/tickets";
import workshops from "./modules/workshops";
import event from "./modules/event";

export default createStore({
  state: {
    currentEventID: null,
    currentEventPastEvent: false,
  },
  mutations: {
    setCurrentEventID(state, payload) {
      state.currentEventID = payload;
    },
    setCurrentEventPastEvent(state, payload) {
      state.currentEventPastEvent = payload;
    },
  },
  actions: {},
  modules: {
    auth,
    crud,
    ui,
    tickets,
    workshops,
    event,
  },
});
