<template>
  <div
    class="slot"
    :style="`min-height: ${height}`"
    :class="{ keynote: data.submission.is_keynote }"
  >
    <div v-if="data.submission.is_keynote" class="badge">Keynote</div>
    <div class="slot__track">{{ makeFieldName(data.track) }}</div>
    <div
      class="slot__content"
      :style="`height: ${height === '490px' ? '444px' : ''}`"
    >
      <div>
        <div class="slot__content-title">{{ shortenText(data.title, 25) }}</div>
        <div v-show="descr" class="normal-font">
          {{ shortenText(data.description, 60) }}
        </div>
      </div>

      <div>
        <div class="d-flex">
          <div
            v-show="data.submission.bio.image"
            class="slot__content-avatar"
            :style="`background-image: url(${data.submission.bio.image})`"
          />
          <div style="line-height: 20px">
            <div>
              {{ data.submission.bio.first_name }}
              {{ data.submission.bio.last_name }}
            </div>
            <div class="grey--text normal-font">
              {{ data.submission.bio.job_title }}
            </div>
            <div class="grey--text normal-font">
              {{ data.submission.bio.company }}
            </div>
          </div>
        </div>

        <!--          <div class="slot__calendar">+ add to calendar</div>-->
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
// eslint-disable-next-line
//   const cal = ics()
import { shortenText, makeFieldName } from "@/assets/js/utils";

export default {
  name: "slot-card",
  props: ["data", "track", "height", "descr"],
  components: {},
  data: () => ({}),
  computed: {},
  created() {},
  methods: {
    shortenText,
    makeFieldName,
    // downloadEventToCalendar: function () {
    //   const start = this.convertTime(this.data.start_time);
    //   const end = this.convertTime(this.data.end_time);
    //   const route = this.baseUrl + "/vc/#/" + this.data.id;
    //   cal.addEvent(this.data.title, this.data.description, route, start, end);
    //   cal.download(this.data.title);
    // },
    // convertTime: function (time) {
    //   const utcTime = moment.utc(time).toDate();
    //   const localTime = moment(utcTime).local().format("YYYY-MM-DDTHH:mm");
    //   const localTimeFormatted = moment(localTime, "YYYY-MM-DDTHH:mm").toDate();
    //   return moment(localTimeFormatted)
    //     .locale("en")
    //     .format("MM/DD/YYYY hh:mm a");
    // },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.normal-font {
  font-weight: normal;
}

.keynote {
  border: 1px solid $blue;
}

.badge {
  position: absolute;
  right: 0;
  top: 0;
  background-color: $blue;
  font-size: 15px;
  padding: 5px 10px;
}

.slot {
  position: relative;
  max-width: 235px;
  width: 100%;
  min-height: 240px;
  margin-right: 22px;
  margin-top: 10px;
  background-color: $light-gray;
  color: $dark-gray;

  &__track {
    padding: 15px 24px;
    font-family: $sarabun;
    font-size: 16px;
    font-weight: $sarabunSemibold;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &__content {
    min-height: 185px;
    padding: 5px 24px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: $openSans;
    font-weight: $sansBold;
    font-size: 17px;
    line-height: 32px;
    letter-spacing: 0.05em;

    &-avatar {
      width: 47px;
      min-width: 47px;
      height: auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 13px;
    }

    &-title {
      padding-bottom: 6px;
    }

    &-job {
      color: $light-gray;
      font-weight: normal;
      font-size: 16px;
    }
  }

  &__calendar {
    cursor: pointer;
    margin-top: 19px;
    font-family: $openSans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border: 1px solid $dark-gray;
    text-align: center;
    padding: 4px;

    &:hover {
      background-color: $light-gray;
    }
  }

  &-higher {
    min-height: 480px;

    &__content {
      min-height: 425px;
    }
  }
}
</style>
