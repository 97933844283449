<template>
  <div class="schedule">
    <div class="schedule__timeline">
      <div
        v-for="slot in slots['show_it']"
        :key="slot.id + 'time'"
        class="schedule__timeline-item"
      >
        {{ getTime(slot.start_time) }}
      </div>
    </div>
    <div
      v-for="[track, list] in Object.entries(slots)"
      :key="track"
      v-show="list && list.length"
      class="ml-8 schedule__track"
    >
      <div v-for="slot in list" :key="slot.id" :id="slot.id">
        <SlotCard
          v-if="!slot.available && Object.keys(slot.submission).length"
          :data="slot"
          :track="track"
          :height="getSlotHeight(slot.start_time, slot.end_time)"
          :descr="needSlotDescr(slot.start_time, slot.end_time)"
          @click="
            $router.push(`/${$route.params.id}/speaker/${slot.submission.id}`)
          "
          style="cursor: pointer"
        />
        <!--          :style="`height: ${getSlotHeight(slot.start_time, slot.end_time)}`"-->
        <div
          v-else-if="!slot.available && !Object.keys(slot.submission).length"
          :style="`height: 240px`"
          class="schedule__technical"
        >
          .
          <img
            src="../../../assets/img/icons/coffee.svg"
            alt="coffee"
            class="schedule__technical-icon"
          />
          {{ slot.title }}
        </div>
        <div
          v-else-if="slot.available"
          :style="`height: ${getSlotHeight(slot.start_time, slot.end_time)}`"
          style="margin-top: 10px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SlotCard from "./SlotCard";

export default {
  name: "ScheduleTable",
  props: ["slots"],
  components: { SlotCard },
  data: () => ({}),
  methods: {
    getTime(dateString) {
      const date = new Date(dateString);
      // Adjust for AKST (UTC-9) and add 6 hours
      const akstDate = new Date(date.getTime() + 15 * 60 * 60 * 1000);
      const hours = akstDate.getUTCHours();
      const minutes = akstDate.getUTCMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes.toString().padStart(2, "0");
      return `${formattedHours}:${formattedMinutes} ${ampm} AKST`;
    },
    // getFirstTrack(obj) {
    //   return Object.keys(obj)[0];
    // },
    getSlotHeight(start, end) {
      const duration = Math.abs(new Date(end) - new Date(start)) / 60000;
      if (duration === 30) {
        return "240px";
      } else if (duration === 60) {
        return "490px";
      }
      return "240px";
    },
    needSlotDescr(start, end) {
      const duration = Math.abs(new Date(end) - new Date(start)) / 60000;
      return duration > 30;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.schedule {
  display: flex;
  justify-content: center;

  &__timeline {
    width: 70px;

    &-item {
      position: relative;
      margin-bottom: 234px;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;

      &:after {
        content: "";
        position: absolute;
        left: 87px;
        top: 3px;
        height: 1px;
        width: 70vw;
        background-color: #2f2f2f;
        z-index: 0;

        @media (min-width: $media-lg) {
          width: 800px;
        }
        @media (min-width: $media-xl) {
          width: 1063px;
        }
      }
    }
  }

  &__track {
    z-index: 1;
  }

  &__technical {
    width: 100%;
    background-color: $white;
    border: 1px solid $dark-gray;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    min-height: 200px;
    padding: 0 25px;
    margin: 10px 22px 0 0;

    &-icon {
      margin-right: 16px;
    }
  }

  @media (max-width: $media-md) {
    overflow-x: scroll;
  }
}
</style>
