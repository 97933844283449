<template>
  <div class="events-container padding-x">
    <Heading noDecoration type="h2" class="mb-3"> Past Events </Heading>
    <div class="events-wrapper">
      <event-card
        past
        v-for="(event, index) in events"
        :data="event"
        :key="index"
      />
    </div>
  </div>
</template>

<script>
import Heading from "../common/Heading.vue";
import EventCard from "./EventCard.vue";
export default {
  props: {
    events: {
      type: Array,
    },
    past: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EventCard,
    Heading,
  },
};

Heading;
</script>

<style lang="scss" scoped>
.events-container {
  margin-top: 48px;

  h4 {
    margin-bottom: 48px;
  }

  .events-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media (min-width: 1800px) {
      justify-content: flex-start;
    }
  }
}
</style>
