<template>
  <Preloader v-if="loading" :loading="loading" />
  <div v-else class="statistic padding-x">
    <div v-if="statistic && statistic.tracks" class="statistic__item">
      <img
        src="../../assets/img/icons/tracks.svg"
        alt="tracks icon"
        class="statistic__item-icon"
      />
      <div class="statistic__item-num">{{ statistic.tracks[0] }}</div>
      <div class="statistic__item-name">Talk Tracks</div>
    </div>
    <div v-if="statistic.villages" class="statistic__item">
      <img
        src="../../assets/img/icons/villages.svg"
        alt="villages icon"
        class="statistic__item-icon"
      />
      <div class="statistic__item-num">{{ statistic.villages[0] }}</div>
      <div class="statistic__item-name">Villages</div>
    </div>
    <div v-if="statistic.sessions" class="statistic__item">
      <img
        src="../../assets/img/icons/sessions.svg"
        alt="sessions icon"
        class="statistic__item-icon"
      />
      <div class="statistic__item-num">{{ statistic.sessions[0] }}</div>
      <div class="statistic__item-name">Sessions</div>
    </div>
    <div v-if="statistic.workshops" class="statistic__item">
      <img
        src="../../assets/img/icons/workshops.svg"
        alt="workshops icon"
        class="statistic__item-icon"
      />
      <div class="statistic__item-num">{{ statistic.workshops[0] }}</div>
      <div class="statistic__item-name">Workshops</div>
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/common/Preloader";

export default {
  name: "StatisticsBlock",
  components: { Preloader },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    statistic: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.statistic {
  $self: &;

  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $dark-gray;
    padding: 50px 0;
    width: 50%;

    &-icon {
      position: absolute;
      opacity: 0.1;
    }

    &-icon:hover {
      opacity: 0.6;
    }

    &-num {
      font-weight: 800;
      font-size: 112px;
      line-height: 112px;
    }
    &-name {
      font-size: 24px;
      text-transform: uppercase;
    }
  }
}

.statistic:before {
  content: "";
  background: $light-gray;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50%;
  width: 100%;
  z-index: -1;
}

@media (min-width: $media-sm) {
  .statistic:before {
    right: 0;
    height: 100%;
    width: 50%;
  }
}

@media (min-width: $media-md) {
  .statistic {
    padding-top: 100px;

    &__item {
      width: 25%;
    }
  }
}

@media (min-width: $media-lg) {
  .statistic:before {
    height: 100%;
    width: 50%;
  }
}
</style>
