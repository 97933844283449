<template>
  <div class="home-page">
    <!--    <Hero :event="event" id="home" />-->
    <MainBlock
      :currentEventID="currentEventID"
      :hasUpcomingEvent="currentEventID"
      :loading="isEventLoading"
      :event="savedEvent"
      id="home"
    />

    <!-- <StatisticsBlock :loading="isEventLoading" :statistic="event.event_stats" /> -->
    <About id="about" v-if="currentEventID" />
    <EventsList
      id="past_events"
      v-if="currentEventID"
      :events="pastEvents"
      past
    />
    <!-- <SponsorAcquire v-if="currentEventID" /> -->
    <!-- <Apply id="apply" v-if="currentEventID" /> -->
    <!-- <Schedule id="schedule" v-if="currentEventID" /> -->
    <!-- <ScheduleSpeakersListVariant v-if="currentEventID" /> -->
    <!-- <Workshops id="workshops" v-if="currentEventID" /> -->
    <!-- <Villages id="villages" v-if="currentEventID" /> -->
    <!-- <Volunteering id="volunteering" v-if="currentEventID" /> -->
    <!-- <Sponsors id="sponsors" v-if="currentEventID" /> -->
    <!--    <Tickets id="tickets" v-if="currentEventID" />-->
    <!-- <Blog v-if="currentEventID" /> -->
    <!-- <ContactUs id="contacts" v-if="currentEventID" /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
// import Hero from "@/components/home/Hero";
import About from "@/components/home/About";
// import SponsorAcquire from "@/components/home/SponsorAcquire";
// import Apply from "@/components/home/Apply";
// import Schedule from "@/components/home/Schedule/Schedule";
// import ScheduleSpeakersListVariant from "@/components/home/Schedule/SpeakersListVariant";
// import Villages from "@/components/home/Villages";
// import Volunteering from "@/components/home/Volunteering";
// import Sponsors from "@/components/home/Sponsors";
// import Tickets from "@/components/home/Tickets";
// import Blog from "@/components/home/Blog";
// import ContactUs from "@/components/home/ContactUs";
import MainBlock from "@/components/home/MainBlock";
// import StatisticsBlock from "@/components/home/StatisticsBlock";
// import Workshops from "@/components/home/Workshops";
import EventsList from "@/components/home/EventsList";
// import Heading from "@/components/common/Heading";

export default {
  name: "Home",
  components: {
    // Heading,
    EventsList,
    // Workshops,
    // StatisticsBlock,
    MainBlock,
    // Hero,
    About,
    // SponsorAcquire,
    // Apply,
    // Schedule,
    // ScheduleSpeakersListVariant,
    // Villages,
    // Volunteering,
    // Sponsors,
    // Tickets,
    // Blog,
  },
  data() {
    return {
      event: {},
      pastEvents: [],
      isEventLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      savedEvent: "getCurrentEventDetails",
    }),
    ...mapState(["currentEventID", "auth/userName"]),
  },
  mounted() {
    this.isEventLoading = true;

    if (this.$route.params.id) {
      this.getCurrentEvent();
    } else {
      this.getNearestEvent();
    }
  },
  methods: {
    ...mapActions(["getEvent", "GET", "POST"]),
    getCurrentEvent() {
      this.$store.commit("setCurrentEventID", this.$route.params.id);
      return this.getEvent(this.$route.params.id || this.currentEventID)
        .then((resp) => {
          this.$store.commit("setCurrentEventDetails", resp);
          this.isEventLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isEventLoading = false;
        });
    },

    getNearestEvent() {
      this.isEventLoading = true;
      return this.GET({ route: "/public/event/upcoming_events/" })
        .then(({ data }) => {
          if ((data && data.length) || this.$route.params.id) {
            const diffDate = Date.now();
            const sortedEvents = data.sort(function (a, b) {
              return (
                Math.abs(diffDate - a.start) - Math.abs(diffDate - b.start)
              );
            });
            console.log({ EVENTTTT: sortedEvents[0] });
            this.$store.commit("setCurrentEventDetails", sortedEvents[0]);

            this.$store.commit("setCurrentEventID", sortedEvents[0].id);
            // this.$router.push(sortedEvents[0].id);
          }
          this.GET({ route: "/public/event/past_events/" }).then(({ data }) => {
            // const event = data[data.length - 1];
            this.pastEvents = data;
            // console.log({ event });
            // this.$store.commit("setCurrentEventID", event.id);
            // this.$store.commit("setCurrentEventDetails", event);
            // this.$router.push(event.id);
            this.isEventLoading = false;
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("setCurrentEventID", "");
          this.$store.commit("resetEventState");
        })
        .finally(() => {
          this.isEventLoading = false;
        });
    },
  },
};
</script>
