<template>
  <nav
    class="nav padding-x"
    :class="{
      'nav--opened': isMobNavOpened,
      'nav--not-home': !isMainPage,
      'nav--sticky': isNavSticky,
    }"
  >
    <router-link :to="$route.params.id ? `/${$route.params.id}` : '/'">
      <picture>
        <source media="(max-width: 719px)" :srcset="logoPath" />
        <source media="(min-width: 720px)" :srcset="logoPath" />
        <img :src="logoPath" alt="ArcticCon logo" class="nav__logo" />
      </picture>
    </router-link>
    <button
      class="nav__toggler"
      :class="{ active: isMobNavOpened, black: isNavSticky || isMobNavOpened }"
      @click="isMobNavOpened = !isMobNavOpened"
    >
      <span />
      <!-- for burger menu -->
    </button>
    <NavMenu
      v-show="isMobNavOpened"
      :menu="navLinks"
      :close="closeMenu"
      :logOut="logOut"
    />
  </nav>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import { debounce } from "@/assets/js/utils";
import NavMenu from "./NavMenu";

export default {
  name: "Navigation",
  props: {},
  components: {
    NavMenu,
  },
  data: () => ({
    isMobNavOpened: false,
    isNavSticky: false,
    links: [
      {
        name: "Home",
        path: "home",
      },
      {
        name: "About ArcticCon",
        path: "about",
      },
      {
        name: "Villages",
        path: "villages",
      },
      {
        name: "Volunteering",
        path: "volunteering",
      },
      {
        name: "Tickets",
        path: "tickets",
      },
      {
        name: "Contact us",
        path: "contacts",
      },
    ],
    futureLinks: [
      {
        name: "Home",
        path: "home",
      },
      {
        name: "About ArcticCon",
        path: "about",
      },
      {
        name: "Apply for a talk",
        path: "apply",
      },
      {
        name: "Villages",
        path: "villages",
      },
      {
        name: "Volunteering",
        path: "volunteering",
      },
      {
        name: "Tickets",
        path: "tickets",
      },
      {
        name: "Contact us",
        path: "contacts",
      },
    ],
    pastLinks: [
      {
        name: "Home",
        path: "home",
      },
      {
        name: "About ArcticCon",
        path: "about",
      },
      {
        name: "Schedule",
        path: "schedule",
      },
      {
        name: "Workshops",
        path: "workshops",
      },
      {
        name: "Villages",
        path: "villages",
      },
      {
        name: "Contact us",
        path: "contacts",
      },
    ],
    linksHome: [
      {
        name: "Home",
        path: "home",
      },
      {
        name: "About ArcticCon",
        path: "about",
      },
      {
        name: "Past Events",
        path: "past_events",
      },
    ],
  }),
  computed: {
    ...mapState(["currentEventID", "currentEventPastEvent"]),
    navLinks() {
      if (this.$route.path === "/") {
        return this.linksHome;
      }
      return this.currentEventPastEvent ? this.pastLinks : this.futureLinks;
    },
    isMainPage() {
      return this.$route.path === `/${this.currentEventID}`;
    },
    logoPath() {
      return this.isNavSticky || !this.isMainPage
        ? require("@/assets/img/ac-logo.png")
        : require("@/assets/img/ac-logo-white.png");
    },

    currentView() {
      return this.$route.name;
    },
  },
  mounted() {
    // temporary changed because of second header in exhibitor kit - now header becomes sticky earlier than First Screen is Scrolled Out
    // window.addEventListener("scroll", this.debouncedChecker());
    window.addEventListener("scroll", () => (this.isNavSticky = true));
  },
  beforeUnmount() {
    // window.removeEventListener("scroll", this.debouncedChecker());
    window.addEventListener("scroll", () => (this.isNavSticky = true));
  },
  watch: {
    currentView(value) {
      if (value !== "Home") this.isMobNavOpened = false;
    },
    // userName(val) {
    //   if (val) {
    //     const nawLinksNoLogin = this.navLinks.filter(
    //       (link) => link.name !== "Login"
    //     );
    //     this.navLinks = [
    //       ...nawLinksNoLogin,
    //       {
    //         name: "Log Out",
    //         path: "logout",
    //       },
    //     ];
    //   }
    // },
  },
  methods: {
    ...mapActions(["signOut"]),
    // debounce,
    // detectIsAboutSectionVisible() {
    //   const screenHeight = window.screen.availHeight;
    //   const scrolledPixels = Math.abs(
    //     document.getElementById("container").getBoundingClientRect().top
    //   );
    //   const isFirstScreenScrolledOut = scrolledPixels >= screenHeight - 300;
    //
    //   this.isNavSticky = isFirstScreenScrolledOut || this.isMobNavOpened;
    // },
    // debouncedChecker() {
    //   return this.debounce(this.detectIsAboutSectionVisible, 200);
    // },
    closeMenu() {
      this.isMobNavOpened = false;
    },
    logOut() {
      this.signOut().catch((err) => {
        console.log(err);
      });
      this.isMobNavOpened = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.nav {
  $self: &;

  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--sticky {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(4px);
  }

  &--opened,
  &--not-home {
    #{$self}__toggler {
      &::before,
      &::after,
      span {
        background-color: $black;
      }
    }
  }

  &--opened {
    background-color: $light-gray;
  }

  &__logo {
    width: 60px;
    display: block;
  }

  &__toggler {
    border: none;
    background: none;
    padding: 0;
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 5;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &::before,
    &::after {
      content: "";
    }

    &::before,
    &::after,
    span {
      position: absolute;
      left: 0;
      height: 2px;
      width: 24px;
      border-radius: 1px;
      background-color: $white;
      transition: 0.3s ease-in-out;
    }

    &::before {
      top: 3px;
    }

    span {
      top: 11px;
    }

    &::after {
      top: 19px;
    }

    &.black {
      &::before,
      &::after {
        background-color: $black;
      }
      span {
        background-color: $black;
      }
    }

    &.active {
      &::before {
        transform: rotate(45deg);
        top: 9px;
        left: 0;
      }

      span {
        width: 0;
        opacity: 0;
      }

      &::after {
        transform: rotate(-45deg);
        top: 9px;
        left: 0;
      }
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 24px;
    padding-bottom: 24px;

    &--opened {
      background-color: transparent;
    }

    /*    &__toggler {
      &::before,
      &::after,
      span {
        background-color: $black;
      }
    }*/

    &__logo {
      width: 90px;
    }
  }

  @media (min-width: $media-lg) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
</style>
