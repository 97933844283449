<template>
  <article class="village-card">
    <img
      :src="imageSrc"
      :alt="data.bio.first_name + data.bio.last_name"
      class="village-card__image"
    />
    <div v-show="data.is_keynote" class="badge">Keynote</div>

    <div class="village-card__text">
      <h4
        class="village-card__title"
        :class="{ 'keynote-line': data.is_keynote }"
      >
        {{ data.bio.first_name }} {{ data.bio.last_name }}
      </h4>
      <div class="village-card__description">
        <p>{{ data.bio.organization }} - {{ data.bio.job_title }}</p>
        <!--        <p><b>Talk:</b> {{ data.talk.title }}</p>-->
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "TalkCard",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    imageSrc() {
      return this.data.bio.image
        ? this.data.bio.image
        : require("@/assets/img/empty-speaker.jpg");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.village-card {
  position: relative;
}

.village-card__description p {
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.badge {
  padding: 7px;
  position: absolute;
  top: 0;
  right: 0;
  width: 88px;
  height: 33px;
  background-color: $blue;
  color: $black;
  font-size: 18px;
  line-height: 15px;
}

.keynote-line {
  &:before {
    background-color: $blue;
  }
}
</style>
