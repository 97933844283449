<template>
  <div>
    <Preloader :loading="isSubmissionLoading" />
    <section
      v-if="submissionsList && submissionsList.length && !isSubmissionLoading"
      class="padding-x speakers"
    >
      <Heading type="h2" text-align="center" class="speakers__title">
        Speakers
      </Heading>
      <Carousel
        wrap-around
        :settings="carouselSettings"
        :breakpoints="carouselBreakpoints"
        class="speakers__carousel"
      >
        <Slide v-for="submission in submissionsList" :key="submission.id">
          <TalkCard :data="submission" class="speakers__card" />
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </Carousel>

      <div class="speakers__list">
        <TalkCard
          v-for="submission in submissionsList"
          :key="submission.id"
          :data="submission"
          @click="$router.push(`/${$route.params.id}/speaker/${submission.id}`)"
          class="speakers__card"
        />
      </div>
    </section>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import Preloader from "@/components/common/Preloader";
import Heading from "@/components/common/Heading";
import TalkCard from "./TalkCard";
import { mapActions } from "vuex";

export default {
  name: "SpeakersListVariant",
  props: {},
  components: { Preloader, Heading, TalkCard, Carousel, Slide, Navigation },
  data: () => ({
    carouselSettings: {
      itemsToShow: 1,
    },
    carouselBreakpoints: {
      720: {
        itemsToShow: 2,
      },
    },
    isSubmissionLoading: false,
    submissionsList: [],
  }),
  mounted() {
    this.isSubmissionLoading = true;
    this.getCurrentEventSchedule();
  },
  methods: {
    ...mapActions(["GET"]),
    getCurrentEventSchedule() {
      return this.GET({
        route: `/public/event/${this.$route.params.id}/submission`,
      })
        .then((resp) => {
          this.submissionsList = resp.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isSubmissionLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.speakers {
  margin: 50px 0;

  &__title {
    margin-bottom: 30px;
  }

  &__list {
    display: none;
  }

  &__card {
    cursor: pointer;
    max-width: 160px;

    &:nth-of-type(4n) {
      margin-right: 0;
    }

    .village-card__image {
      height: 180px;
      width: 160px;
    }
  }

  @media (min-width: $media-sm) {
    &__title {
      margin-bottom: 88px;
    }

    &__carousel {
      margin: 0 120px;
    }

    &__card {
      max-width: 200px;

      .village-card__image {
        height: 220px;
        width: 200px;
      }
    }
  }

  @media (min-width: $media-lg) {
    margin: 120px 0 20px;

    &__carousel {
      display: none;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__card {
      margin-right: 24px;
    }
  }

  @media (min-width: 1800px) {
    &__card {
      &:nth-of-type(4n) {
        margin-right: 24px;
      }
      &:nth-of-type(5n) {
        margin-right: 0;
      }
    }
  }
}
</style>
