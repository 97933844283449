<template>
  <div class="preloader-wrapper" :class="{ 'no-padding': !loading }">
    <square-loader
      :loading="loading"
      :color="color"
      :size="size"
    ></square-loader>
  </div>
</template>

<script>
import SquareLoader from "vue-spinner/src/SquareLoader.vue";

export default {
  name: "Preloader",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "black",
    },
    size: {
      type: String,
      default: "50px",
    },
  },
  components: {
    SquareLoader,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.preloader-wrapper {
  padding: 40px 10px;
  display: flex;
  justify-content: center;
}

.no-padding {
  padding: 0;
}
</style>
