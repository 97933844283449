<template>
  <div>
    <Preloader :loading="isBlogpostsLoading" />
    <section v-if="blogPosts.length" class="blog padding-x">
      <Heading type="h2" text-align="center" class="blog__heading">
        Blog
      </Heading>

      <Carousel wrap-around items-to-show="3">
        <Slide v-for="post in blogPosts" :key="post.id">
          <BlogCard
            :data="post"
            :image="post.primary_image"
            @click="$router.push(`/${currentEventID}/blog/${post.id}`)"
            class="blog__post"
          />
        </Slide>
        <template #addons>
          <Navigation v-if="blogPosts.length > 3" />
        </template>
      </Carousel>
    </section>
    <!-- <ComingSoon v-else entity="Blogposts" class="mt-16 mb-10" /> -->
  </div>
</template>

<script>
import Preloader from "@/components/common/Preloader";
import Heading from "@/components/common/Heading";
import BlogCard from "@/components/common/CommonCard";
// import ComingSoon from "@/components/common/ComingSoon";
import { mapActions, mapState } from "vuex";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  name: "Blog",
  props: {},
  components: {
    Preloader,
    Heading,
    // ComingSoon,
    BlogCard,
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    isBlogpostsLoading: false,
    blogPosts: [],
  }),
  computed: {
    ...mapState(["currentEventID"]),
  },
  mounted() {
    this.isBlogpostsLoading = true;
    this.getBlogposts();
  },
  methods: {
    ...mapActions(["GET"]),
    getBlogposts() {
      return this.GET({ route: `/public/blogpost/?tag=${this.currentEventID}` })
        .then((resp) => {
          if (resp.data) {
            let postsArr = [];
            resp.data.map((post) => {
              postsArr.push({
                ...post,
                name: post.title,
                description: post.content,
              });
            });
            // this.blogPosts = postsArr.slice(0, 3);
            // console.log(postsArr);
            this.blogPosts = postsArr;
          }
          this.isBlogpostsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isBlogpostsLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.blog {
  padding-top: 32px;
  margin-bottom: 50px;

  &__heading {
    margin-bottom: 16px;
  }

  &__post {
    cursor: pointer;
  }

  @media (min-width: $media-xs) {
    &__post {
      padding: 0 8px;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 120px;

    &__heading {
      margin-bottom: 52px;
    }
  }

  @media (min-width: $media-lg) {
    margin-bottom: 150px;
    &__heading {
      margin-bottom: 82px;
    }
  }
}
</style>
