import Amplify, { Auth } from "aws-amplify";
import router from "../../router/index";
import axios from "axios";

const oauth = {
  domain: process.env.VUE_APP_COGNITO_DOMAIN,
  redirectSignIn: process.env.VUE_APP_REDIRECT_URL,
  redirectSignOut: process.env.VUE_APP_REDIRECT_URL,
  responseType: "token",
};

Amplify.configure({
  Auth: {
    region: "us-west-2",
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: "USER_PASSWORD_AUTH",
    oauth,
  },
});

const state = {
  userName: "",
  user: {},
};

const getters = {
  getUserName: (state) => state.userName,
  getUser: (state) => state.user,
};

const mutations = {
  setUserName(state, payload) {
    state.userName = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
};

const actions = {
  signIn: function ({ commit }, creds) {
    return new Promise((resolve, reject) => {
      Auth.signIn(creds.username.trim(), creds.password)
        .then((user) => {
          axios.defaults.headers.common["Authorization"] =
            user.signInUserSession.idToken.jwtToken;
          commit("setUserName", user.username);
          commit("setUser", user);
          resolve(user);
        })
        .catch((err) => {
          commit("setUserName", "");
          localStorage.clear();
          reject(err);
        });
    });
  },
  signInSocialMedia: function (context, provider = "Facebook") {
    Auth.federatedSignIn({ provider });
  },
  signUp: function (_, creds) {
    return new Promise((resolve, reject) => {
      Auth.signUp({
        username: creds.username.trim(),
        password: creds.password,
        attributes: {
          email: creds.email,
        },
        validationData: [],
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  confirmSignUp: function (_, creds) {
    return new Promise((resolve, reject) => {
      Auth.confirmSignUp(creds.username, creds.code, {
        forceAliasCreation: true,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resendSignUp: function (_, creds) {
    return new Promise((resolve, reject) => {
      Auth.resendSignUp(creds.username)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCurrentToken() {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((user) => {
          // store.dispatch('updateUser', user.idToken.payload['cognito:username'])
          let token = user.idToken.jwtToken;
          // this.$axios.defaults.headers.common["Authorization"] = token;
          resolve(token);
        })
        .catch((err) => {
          localStorage.clear();
          reject(err);
        });
    });
  },
  obtainToken(_, creds) {
    return new Promise((resolve, reject) => {
      Auth.signIn(creds.username, creds.password)
        .then((user) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            router.push({
              name: "CompleteNewPassword",
              params: {
                id: creds.username.toLowerCase(),
                password: creds.password,
              },
              query: creds.query,
            });
          } else {
            let token = user.signInUserSession.idToken.jwtToken;
            // this.$axios.defaults.headers.common["Authorization"] = token;
            resolve(token);
          }
        })
        .catch((err) => {
          localStorage.clear();
          reject(err);
        });
    });
  },
  getCurrentAuthedUser: function () {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          resolve(user);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCurrentUser: function ({ commit }) {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((user) => {
          commit("setUserName", user.idToken.payload["cognito:username"]);
          resolve(user.idToken.payload["cognito:username"]);
          commit("setUser", user);
        })
        .catch((err) => {
          commit("setUserName", "");
          reject(err);
        });
    });
  },
  getCurrentUserGroups: function () {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((user) => {
          resolve(user.idToken.payload["cognito:groups"]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  signOut({ commit }) {
    return new Promise((resolve, reject) => {
      Auth.signOut()
        .then((data) => {
          commit("setUserName", "");
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  changePassword(_, params) {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser().then((user) => {
        Auth.changePassword(user, params.oldPassword, params.newPassword)
          .then(() => {
            router.push("/login");
          })
          .catch((err) => {
            localStorage.clear();
            reject(err);
          });
      });
    });
  },
  completeNewPassword: function (_, params) {
    return new Promise((resolve, reject) => {
      Auth.signIn(params.username, params.oldPassword)
        .then((user) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(user, params.newPassword, {
              name: params.name,
              email: params.email,
              phone_number: params.number,
            })
              .then(() => {
                router.push("/");
              })
              .catch((err) => {
                reject(err);
              });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  forgotPassword: function (_, params) {
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(params)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  forgotPasswordSubmit: function (_, params) {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(params.username, params.code, params.password)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default { state, getters, mutations, actions };
