import axios from "axios";

const getDefaultState = () => {
  return {
    currentEvent: {},
    currentEventPastEvent: true,
  };
};

const state = getDefaultState(); // initial state

const getters = {
  getCurrentEventDetails: (state) => state.currentEvent,
};

const mutations = {
  setCurrentEventPastEvent(state, payload) {
    state.currentEventPastEvent = payload;
  },
  setCurrentEventDetails(state, payload) {
    state.currentEvent = payload;
  },
  resetEventState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  getEvent: function ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/public/event/${id}`)
        .then(({ data }) => {
          commit("setCurrentEventDetails", data.data);
          resolve(data.data);
        })
        .catch((err) => {
          commit("resetEventState");
          reject(err);
        });
    });
  },
};

export default { state, getters, mutations, actions };
