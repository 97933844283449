<template>
  <div class="not-found padding-x">
    <div class="not-found__title">404 <span>ERROR</span></div>
    <div>This page doesn’t exist</div>
    <div class="not-found__actions">
      <Button full-width @click="$router.push(`/`)"> Go to home page</Button>
      <Button
        white
        full-width
        @click="$router.go(-1)"
        class="not-found__actions-second"
      >
        Back to previous
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/common/Button";

export default {
  name: "NotFound",
  props: {},
  components: { Button },
  data: () => ({}),
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.not-found {
  padding-top: 106px;
  height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;

  &__title {
    font-family: $sarabun;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $dark-gray;

    span {
      -webkit-text-fill-color: $blue;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $black;
      user-select: none; /* Standard */
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    &-second {
      margin-top: 24px;
    }
  }
}

@media (min-width: $media-sm) {
  .not-found {
    &__title {
      font-size: 56px;
      line-height: 64px;
      letter-spacing: 0.08em;
    }
    &__actions {
      width: 55%;
      margin-top: 30%;
    }
  }
}

@media (min-width: $media-md) {
  .not-found {
    &__actions {
      flex-direction: row;
      width: 70%;
      margin-top: 10%;
      &-second {
        margin: 0 0 0 24px;
      }
    }
  }
}

@media (min-width: $media-lg) {
  .not-found {
    &__title {
      font-size: 80px;
      line-height: 88px;
    }
  }
}
</style>
