<template>
  <div class="home-page">
    <!--    <Hero :event="event" id="home" />-->
    <MainBlock :loading="isEventLoading" :event="savedEvent" id="home" />
    <StatisticsBlock
      :loading="isEventLoading"
      :statistic="savedEvent.event_stats"
    />
    <About id="about" v-if="currentEventID" />
    <!-- <SponsorAcquire v-if="currentEventID" /> -->
    <Apply id="apply" v-if="currentEventID && !isPastEvent" />
    <Schedule
      id="schedule"
      v-if="currentEventID"
      :publishSchedule="savedEvent.publish_schedule"
    />
    <ScheduleSpeakersListVariant v-if="currentEventID" />
    <Workshops id="workshops" v-if="currentEventID" />
    <Villages id="villages" v-if="currentEventID" />
    <Volunteering id="volunteering" v-if="currentEventID && !isPastEvent" />
    <Sponsors id="sponsors" v-if="currentEventID" />
    <Tickets id="tickets" v-if="currentEventID && !isPastEvent" />
    <Blog v-if="currentEventID" />
    <ContactUs id="contacts" v-if="currentEventID" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
// import Hero from "@/components/home/Hero";
import About from "@/components/home/About";
// import SponsorAcquire from "@/components/home/SponsorAcquire";
import Apply from "@/components/home/Apply";
import Schedule from "@/components/home/Schedule/Schedule";
import ScheduleSpeakersListVariant from "@/components/home/Schedule/SpeakersListVariant";
import Villages from "@/components/home/Villages";
import Volunteering from "@/components/home/Volunteering";
import Sponsors from "@/components/home/Sponsors";
import Tickets from "@/components/home/Tickets";
import Blog from "@/components/home/Blog";
import ContactUs from "@/components/home/ContactUs";
import MainBlock from "@/components/home/MainBlock";
import StatisticsBlock from "@/components/home/StatisticsBlock";
import Workshops from "@/components/home/Workshops";

export default {
  name: "Home",
  components: {
    Workshops,
    StatisticsBlock,
    MainBlock,
    // Hero,
    About,
    // SponsorAcquire,
    Apply,
    Schedule,
    ScheduleSpeakersListVariant,
    Villages,
    Volunteering,
    Sponsors,
    Tickets,
    Blog,
    ContactUs,
  },
  data() {
    return {
      isPastEvent: false,
      event: {},
      isEventLoading: true,
      currentEventPastEvent: false,
    };
  },
  computed: {
    ...mapGetters({
      savedEvent: "getCurrentEventDetails",
    }),
    ...mapState(["currentEventID", "auth/userName"]),
  },
  mounted() {
    this.getCurrentEvent();
  },
  methods: {
    ...mapActions(["getEvent", "GET", "POST"]),
    getCurrentEvent() {
      this.$store.commit("setCurrentEventID", this.$route.params.id);
      return this.getEvent(this.$route.params.id || this.currentEventID)
        .then((resp) => {
          const dateNow = Date.now();
          this.isPastEvent = dateNow >= new Date(resp.start);
          this.$store.commit("setCurrentEventDetails", resp);
          this.$store.commit("setCurrentEventPastEvent", this.isPastEvent);
          this.isEventLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isEventLoading = false;
        });
    },
  },
};
</script>
