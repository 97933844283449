<template>
  <Container ref="containerNew">
    <router-view></router-view>
    <!--    <UsernameModal-->
    <!--      :value="showUsernameModal"-->
    <!--      @submit="updateUsername($event)"-->
    <!--    />-->
  </Container>
</template>

<script>
// import { Auth } from "aws-amplify";
import Container from "@/components/container/Container";
// import UsernameModal from "@/components/user/UsernameModal";
// import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Container,
    // UsernameModal
  },
  data() {
    return {
      isEventLoading: false,
      // userDoesNotExist: false,
      // showUsernameModal: false,
    };
  },
  computed: {
    /*    ...mapGetters({
      userName: "getUserName",
      user: "getUser",
    }),*/
  },
  created() {
    /*    this.$store.dispatch("getCurrentUser").then((username) => {
      const parsedUsername = username.includes("|")
        ? username.split("|").join("_")
        : username;
      this.$store
        .dispatch("GET", {
          authed: true,
          route: `/user/${parsedUsername}`,
        })
        .catch(() => {
          this.userDoesNotExist = true;
          this.showUsernameModal = true;`
        });
    });*/
  },
  methods: {
    /*    updateUsername(form) {
      const parsedUsername = this.userName.includes("|")
        ? this.userName.split("|").join("_")
        : this.userName;
      if (this.userDoesNotExist) {
        this.$store
          .dispatch("POST", {
            route: "/user",
            data: {
              email: this.user.idToken.payload.email,
              id: parsedUsername,
              display_name: form.username,
            },
            authed: true,
          })
          .then(() => {
            this.showUsernameModal = false;
          });
      } else {
        this.$store
          .dispatch("PUT", {
            route: `/user/${parsedUsername}`,
            data: {
              display_name: form.username,
            },
          })
          .then(() => {
            this.showUsernameModal = false;
          });
        Auth.updateUserAttributes(this.user, {
          "cognito:username": form.username,
        });

        this.$store.commit("setUserName", form.username);
        Auth.updateUserAttributes(this.user, {
          "cognito:username": form.username,
        });
      }
    },*/
  },
};
</script>
