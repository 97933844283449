<template>
  <div class="container" id="container">
    <Nav />
    <slot />
    <Footer class="container__footer" />
  </div>
</template>

<script>
import Nav from "./Nav";
import Footer from "./Footer";
import { mapActions } from "vuex";

export default {
  name: "Container",
  props: {},
  components: {
    Nav,
    Footer,
  },
  data: () => ({}),
  computed: {
    hash() {
      return this.$route.hash.split("#")[1];
    },
  },
  mounted() {
    this.scrollToSection(this.hash);
    this.getCognitoUserName();
  },
  watch: {
    hash(val) {
      this.$nextTick(() => {
        this.scrollToSection(val);
      });
    },
  },
  methods: {
    ...mapActions({
      getCognitoUserName: "getCurrentUser",
    }),
    scrollToSection(hash) {
      if (hash) {
        const elem = document.getElementById(hash);
        elem.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.container {
  min-height: 100vh;
  display: flex;
  flex-flow: column;

  &__footer {
    margin-top: auto;
  }
}
</style>
