<template>
  <div class="menu">
    <ul class="menu__list">
      <li v-for="item in menu" :key="item.name" class="menu__item">
        <router-link
          :to="{ path: `${path}`, hash: `#${item.path}` }"
          class="menu__link"
          @click="close"
        >
          {{ item.name }}
        </router-link>
      </li>
      <li class="menu__item">
        <router-link
          :to="`/${currentEventID}/sponsor`"
          class="menu__link"
          @click="close"
        >
          Sponsoring
        </router-link>
      </li>
      <li class="menu__item" style="cursor: pointer">
        <a v-show="userName" class="menu__link" @click="logOut"> Log out </a>
      </li>
      <!--      <li class="menu__item">
        <a
          :href="`${baseUrl}virtualconf/#/${currentEventID}`"
          class="menu__link"
          @click="close"
        >
          Login to Platform
        </a>
      </li>-->
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "NavMenu",
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
    close: {
      type: Function,
      default: () => {},
    },
    logOut: {
      type: Function,
      default: () => {},
    },
  },
  components: {},
  data: () => ({}),
  computed: {
    ...mapState(["currentEventID"]),
    ...mapGetters({ userName: "getUserName" }),
    path() {
      return this.$route.params.id ? `/${this.$route.params.id}` : "/";
    },
    baseUrl() {
      return process.env.VUE_APP_BASE_URL.split("api/")[0];
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.menu {
  min-height: calc(100vh - 72px);
  width: 100%;
  /*overflow-y: scroll;*/
  position: fixed;
  top: 72px;
  right: 0;
  background-color: $light-gray;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 24px;
    width: 100%;
    background-color: $blue;
  }

  &__list {
    padding-left: 0;
    list-style-type: none;
    text-align: right;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100px;
      height: 1px;
      background-color: $black;
    }
  }

  &__item {
    padding: 5px 16px 5px 0;
  }

  &__link {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1;
    color: $black;
    position: relative;
    transition: opacity 0.3s;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      height: 1px;
      width: 0;
      background-color: $black;
      transition: width 0.3s;
    }

    @media (hover: hover) {
      &:hover::after {
        width: 80%;
      }
    }

    &:active {
      opacity: 0.6;
    }
  }

  @media (min-width: $media-sm) {
    width: 320px;
    top: 108px;
    min-height: calc(100vh - 108px);

    &::before {
      content: "";
      position: absolute;
      min-height: 100vh;
      width: 100%;
      top: -108px;
      right: 0;
      background-color: $light-gray;
      box-shadow: -1px 0 5px 0 rgba($black, 30%);
    }

    &__item {
      padding: 5px 24px 5px 0;
    }
  }

  @media (min-width: $media-lg) {
    width: calc(15vw + 220px + 42px);

    &::before {
      height: 124px;
    }

    &__item {
      padding-right: 15vw;
    }

    &__list {
      &::before {
        width: calc(15vw + 50px);
      }
    }
  }

  @media (min-width: $media-xl) {
    width: calc(20vw + 220px + 42px);

    &__item {
      padding-right: 20vw;
    }

    &__list {
      &::before {
        width: calc(20vw + 50px);
      }
    }
  }
}
</style>
