<template>
  <div>
    <Preloader :loading="isWorkshopsLoading" />
    <section
      v-if="workshops && workshops.length && !isWorkshopsLoading"
      class="workshops padding-x"
    >
      <Heading type="h2" text-align="center" class="workshops__heading">
        Workshops
      </Heading>
      <div
        class="workshops__item"
        v-for="workshop in workshops"
        :key="workshop.id"
        @click="$router.push(`/${currentEventID}/workshop/${workshop.id}`)"
      >
        <div
          class="workshops__item-img"
          :style="`background-image: url(${
            workshop.primary_image
              ? workshop.primary_image
              : require('../../assets/img/village-sample.jpg')
          } )`"
        ></div>
        <div>
          <div class="workshops__item-title">
            {{ workshop.title }}
          </div>
          <div class="workshops__item-instructor">
            Instructor{{ workshop.instructor.length > 1 ? "s" : null }}:
            <span
              v-for="(guy, index) in workshop.instructor"
              :key="guy.instructor + index"
            >
              <span v-if="index !== 0">, </span>
              {{ guy.instructor }}
            </span>
          </div>
          <div class="workshops__item-time my-2">
            <img
              src="../../assets/img/icons/calendar.svg"
              alt="sessions icon"
              class="mr-2"
            />
            {{ getWorkshopDay(workshop.start) }}
          </div>
          <div
            v-html="workshop.description"
            class="workshops__item-description"
          />
        </div>
      </div>
    </section>
    <section v-else class="workshops padding-x">
      <Heading type="h2" text-align="center" class="workshops__heading">
        Workshops
      </Heading>
      <div class="workshops__item">
        <div
          class="workshops__item-img"
          :style="`background-image: url(${require('../../assets/img/village-sample.jpg')} )`"
        ></div>
        <div>
          <p>
            ArcticCon workshops cover range of technical topics and responses to
            emerging threats. You will get hands-on experience by working with
            cyber security experts and industry leaders.
          </p>
          <br />
          <p>
            We usually announce workshop topics a few weeks before the event and
            typically hold them the day before event.
          </p>
          <br />
          <p>
            If you are interested to learn more, sign up with your email and we
            will notify you once the workshop registration opens.
          </p>
          <transition name="fade">
            <FormSuccess v-if="isFormSent">
              Thank you for your interest in ArcticCon Workshops! We'll send you
              an update as soon as available.
            </FormSuccess>
          </transition>
          <transition name="fade">
            <form
              v-if="!isFormSent"
              @submit.prevent="submitWorkshopSignupForm"
              class="workshop-signup__form"
            >
              <Input
                v-model="form.email.value"
                :error="form.email.error"
                :disabled="isFormSending"
                :name="name"
                :label="form.email.label"
                type="email"
                @input="clearError('email', form)"
                @blur="validateField('email', form)"
              />
              <Button
                class="workshop-signup__button mt-4 mb-2"
                style="height: 56px"
                :loading="workshopSignupLoading"
              >
                submit
              </Button>
              <transition name="fade">
                <Error v-if="isWorkshopSignupError">
                  <template #heading> Something went wrong </template>
                  <template #body>
                    Please try again or contact us at
                    <a href="mailto:info@arctic-con.com" class="error__link">
                      info@arctic-con.com
                    </a>
                  </template>
                </Error>
              </transition>
            </form>
          </transition>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import Preloader from "@/components/common/Preloader";
import Button from "@/components/common/Button";
import Input from "@/components/common/Input";
import FormSuccess from "@/components/common/FormSuccess";
import Error from "@/components/common/Error";
import {
  validateField,
  validateForm,
  clearError,
} from "@/assets/js/validation";
import { mapActions, mapState } from "vuex";
import { transformForm } from "@/assets/js/utils";

export default {
  name: "Workshops",
  props: {},
  components: {
    Preloader,
    Heading,
    Button,
    Input,
    FormSuccess,
    Error,
  },
  data: () => ({
    workshops: [],
    isWorkshopsLoading: false,
    workshopSignupLoading: false,
    isFormSent: false,
    isWorkshopSignupError: false,
    form: {
      email: {
        value: "",
        error: "",
        rules: ["required", "email"],
        label: "Email",
      },
    },
  }),

  computed: {
    ...mapState(["currentEventID"]),
  },
  mounted() {
    this.isWorkshopsLoading = true;
    this.getWorkshopsList();
  },
  methods: {
    ...mapActions(["GET", "POST"]),
    transformForm,
    validateField,
    validateForm,
    clearError,
    getWorkshopsList() {
      return this.GET({
        route: `/public/workshop?tag=${this.currentEventID}`,
      })
        .then((resp) => {
          this.isWorkshopsLoading = false;
          this.workshops = resp.data;
        })
        .catch((err) => {
          console.log(err);
          this.isWorkshopsLoading = false;
        });
    },
    getWorkshopDay(day) {
      const startDate = new Date(day);
      return startDate.toLocaleString("en-US", {
        timeZone: "US/Alaska",
        day: "2-digit",
        month: "long",
      });
    },
    submitWorkshopSignupForm() {
      const isValid = this.validateForm(this.form);
      if (!isValid) return;
      this.workshopSignupLoading = true;
      const formData = this.transformForm(this.form);
      this.POST({
        formRoute: false,
        route: `public/event/${
          this.eventId || this.currentEventID
        }/workshop_signup`,
        data: formData,
      })
        .then((res) => {
          const query = {
            email: res.data.email,
            form_type: "workshop",
            event_id: this.eventId || this.currentEventID,
          };
          this.POST({
            formRoute: true,
            route: "form/workshop",
            data: query,
          })
            .then(() => {
              this.workshopSignupLoading = false;
              this.isFormSent = true;
              this.form.email.value = "";
            })
            .catch(() => {
              this.isWorkshopSignupError = true;
              this.workshopSignupLoading = false;
              this.form.email.value = "";
            });
        })
        .catch((err) => {
          this.POST({
            formRoute: true,
            route: "form/workshop/error",
            data: err,
          });
          this.isWorkshopSignupError = true;
          this.workshopSignupLoading = false;
          this.form.email.value = "";
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
.workshop-signup {
  &__form {
    margin-top: 16px;
  }

  &__button {
    margin-left: auto;
  }

  @media (min-width: $media-sm) {
    &__form {
      display: flex;
      column-gap: 16px;
      margin-top: 32px;
    }
  }

  @media (min-width: $media-md) {
    &__form {
      display: flex;
      column-gap: 16px;
      margin-top: 32px;
    }
  }
}
.workshops {
  padding: 50px 0;

  &__button {
    width: 100%;
  }

  &__heading {
    margin-bottom: 17px;
  }

  &__item {
    cursor: pointer;
    letter-spacing: 0.05em;
    margin-bottom: 13px;

    &-img {
      width: 100%;
      height: 214px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &-title {
      font-family: $sarabun;
      font-weight: $sansBold;
      font-size: 24px;
      letter-spacing: 0.08em;
      color: black;
    }
    &-instructor {
      color: $dark-gray;
      font-weight: $sansBold;
    }
    &-block {
      background: $light-gray;
    }

    &-time {
      display: flex;
      color: $dark-gray;
      font-weight: $sansBold;
    }
  }

  @media (min-width: $media-sm) {
    &__heading {
      margin-bottom: 50px;
    }

    &__item {
      display: flex;

      &-img {
        width: 208px;
        height: 286px;
        min-height: 286px;
        min-width: 208px;
        margin-right: 3%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
  @media (min-width: $media-md) {
    &__item {
      margin-bottom: 24px;
      &-title {
        margin: 20px 0;
      }
      &-img {
        min-width: 296px;
        min-height: 408px;
        width: 296px;
        height: 408px;
      }
    }
  }
  @media (min-width: $media-lg) {
    padding: 120px 0 20px;

    &__heading {
      margin-bottom: 82px;
    }

    &__item {
      margin-bottom: 30px;
      &-img {
        min-width: 368px;
        min-height: 504px;
        width: 368px;
        height: 504px;
      }
    }
  }
}
</style>
