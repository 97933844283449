<template>
  <div>
    <div v-if="publishSchedule && loaded.length === 4" class="schedule">
      <Heading noDecoration text-align="center" class="schedule__heading">
        Schedule
      </Heading>
      <ScheduleTable :slots="slots" />
    </div>
    <Preloader v-else :loading="loaded.length !== 4" />
    <!-- <ComingSoon v-if="isScheduleEmpty" entity="Schedule" class="mt-16" /> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Preloader from "@/components/common/Preloader";
import Heading from "@/components/common/Heading";
// import ComingSoon from "@/components/common/ComingSoon";
import ScheduleTable from "./ScheduleTable";

export default {
  name: "Schedule",
  props: {
    publishSchedule: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ScheduleTable,
    // ComingSoon,
    Preloader,
    Heading,
  },
  data: () => ({
    loaded: [],
    tracks: ["break_it", "build_it", "show_it", "firetalks"],
    slots: {},
  }),
  mounted() {
    this.tracks.map((i) => {
      this.slots[`${i}`] = [];
      this.getScheduleByTrack(i);
    });
  },
  methods: {
    ...mapActions(["GET"]),
    getScheduleByTrack(track) {
      return this.GET({
        route: `/public/event/${this.$route.params.id}/track/${track}/schedule?sorting=true`,
      })
        .then(({ data }) => {
          if (data) this.slots[track] = data;
          this.loaded.push(track);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
.schedule {
  &__heading {
    padding-top: 128px;
    margin-bottom: 30px;
  }

  @media (min-width: $media-sm) {
    &__heading {
      margin-bottom: 56px;
    }
  }
  @media (min-width: $media-sm) {
    &__heading {
      margin-bottom: 87px;
    }
  }
}
</style>
