<template>
  <button
    class="button"
    v-bind="$attrs"
    :disabled="disabled || loading"
    :loading="loading"
    :class="{
      'button-empty': isEmpty,
      'button-full-width': fullWidth,
      'button-white': white,
    }"
  >
    <slot v-if="!loading" />
    <clip-loader :loading="loading" color="white" />
  </button>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader";

export default {
  name: "Button",
  inheritAttrs: false,
  props: {
    isEmpty: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ClipLoader,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.button {
  display: block;
  padding: 0;
  border: 4px solid $black;
  background-color: $black;
  color: $white;
  font-family: $sarabun;
  font-weight: $sarabunSemibold;
  font-size: 1.3rem;
  line-height: 1;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  height: 56px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, opacity 0.3s;

  &:hover {
    background-color: transparent;
    color: $black;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;

    &:hover {
      background-color: initial;
      color: initial;
    }
  }

  &-full-width {
    width: 100%;
  }

  &-white {
    background-color: transparent;
    color: $black;
  }
  &-white:hover {
    background-color: $black;
    color: $white;
  }

  &-empty {
    height: 40px;
    background-color: transparent;
    color: $black;
    border: 2px solid $black;
    font-size: 1rem;
    line-height: 1.3;

    &:hover {
      background-color: $black;
      color: $white;
    }
  }

  @media (min-width: $media-sm) {
    height: 72px;

    &-empty {
      height: 56px;
    }
  }
}
</style>
