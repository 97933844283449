import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store/index";
import axios from "axios";

import NotFound from "../views/NotFound";

import Event from "../views/Event.vue";
import Home from "../views/Home.vue";

const PasswordReset = () =>
  import(
    /* webpackChunkName: "PasswordReset" */ "../components/auth/forgotPassword/NewPassword.vue"
  );
const PrivacyPolicy = () =>
  import(
    /* webpackChunkName: "PrivacyPolicy" */ "../views/policies/PrivacyPolicy.vue"
  );
const AttendeePrivacyPolicy = () =>
  import(
    /* webpackChunkName: "AttendeePrivacyPolicy" */ "../views/policies/AttendeePrivacyPolicy.vue"
  );
const TermsOfService = () =>
  import(
    /* webpackChunkName: "TermsOfService" */ "../views/policies/TermsOfService.vue"
  );
const CookiePolicy = () =>
  import(
    /* webpackChunkName: "CookiePolicy" */ "../views/policies/CookiePolicy.vue"
  );
const SponsorGeneral = () =>
  import(
    /* webpackChunkName: "SponsorGeneral" */ "../views/SponsorGeneral.vue"
  );
const SponsorshipPolicy = () =>
  import(
    /* webpackChunkName: "SponsorshipPolicy" */ "../views/policies/SponsorshipPolicy"
  );
const CFP = () => import(/* webpackChunkName: "CFP" */ "../views/CFP.vue");
const Checkout = () =>
  import(/* webpackChunkName: "Checkout" */ "../views/CheckoutPage.vue");
const Codes = () =>
  import(/* webpackChunkName: "Codes" */ "../views/CodesPage.vue");
const Ticket = () =>
  import(/* webpackChunkName: "Ticket" */ "../views/TicketPage.vue");
const CPE = () => import(/* webpackChunkName: "CPE" */ "../views/CPEPage.vue");
const ExhibitorKit = () =>
  import(
    /* webpackChunkName: "ExhibitorKit" */ "../views/ExhibitorKitPage.vue"
  );
const SpeakerKit = () =>
  import(/* webpackChunkName: "SpeakerKit" */ "../views/SpeakerKitPage.vue");
const Join = () => import(/* webpackChunkName: "Join" */ "../views/Join.vue");
const Register = () =>
  import(/* webpackChunkName: "Register" */ "../views/Register.vue");
const RegisterMultiple = () =>
  import(
    /* webpackChunkName: "RegisterMultiple" */ "../views/RegisterMultiple.vue"
  );
const Invite = () =>
  import(/* webpackChunkName: "Invite" */ "../views/Invite.vue");
const Blog = () => import(/* webpackChunkName: "Blog" */ "../views/Blog.vue");
const Village = () =>
  import(/* webpackChunkName: "Village" */ "../views/VillagePage.vue");
const Speaker = () =>
  import(/* webpackChunkName: "Speaker" */ "../components/speaker/Speaker.vue");
const Workshop = () =>
  import(/* webpackChunkName: "Workshop" */ "../views/WorkshopPage.vue");
const WorkshopApply = () =>
  import(
    /* webpackChunkName: "WorkshopApply" */ "../views/WorkshopRegistrationPage.vue"
  );

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/:id",
    name: "Event",
    component: Event,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/attendee-privacy-policy",
    name: "AttendeePrivacyPolicy",
    component: AttendeePrivacyPolicy,
  },
  {
    path: "/terms-of-service",
    name: "TermsOfService",
    component: TermsOfService,
  },
  {
    path: "/cookie-policy",
    name: "CookiePolicy",
    component: CookiePolicy,
  },
  {
    path: "/:id/sponsor",
    name: "SponsorGeneral",
    component: SponsorGeneral,
  },
  {
    path: "/sponsorship-terms-and-conditions",
    name: "SponsorshipPolicy",
    component: SponsorshipPolicy,
  },
  {
    path: "/:id/cfp",
    name: "CFP",
    component: CFP,
  },
  {
    path: "/:id/checkout/:ticket?",
    name: "Checkout",
    component: Checkout,
  },
  {
    path: "/:id/join/:code?",
    name: "Join",
    component: Join,
  },
  {
    path: "/:id/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/:id/invite/:code",
    name: "Invite",
    component: Invite,
  },
  {
    path: "/:id/register-multiple/:code",
    name: "RegisterMultiple",
    component: RegisterMultiple,
  },
  {
    path: "/:id/tickets/:token",
    name: "Codes",
    component: Codes,
  },
  {
    path: "/:id/ticket/:token",
    name: "Ticket",
    component: Ticket,
  },
  {
    path: "/:id/cpe/:token",
    name: "CPE",
    component: CPE,
  },
  {
    path: "/:id/exhibitor-kit/:token",
    name: "Exhibitor kit",
    component: ExhibitorKit,
  },
  {
    path: "/:id/speaker-kit/:token",
    name: "Speaker Kit",
    component: SpeakerKit,
  },
  {
    path: "/:id/blog/:blog_id",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/:id/village/:village_id",
    name: "Village",
    component: Village,
  },
  {
    path: "/:id/speaker/:speaker_id",
    name: "Speaker",
    component: Speaker,
  },
  {
    path: "/:id/workshop/:workshop_id",
    name: "Workshop",
    component: Workshop,
  },
  {
    path: "/:id/workshop/:workshop_id/apply",
    name: "WorkshopApply",
    component: WorkshopApply,
  },
  {
    path: "/password_reset/:userName/:code",
    name: "PasswordReset",
    component: PasswordReset,
  },
  { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        behavior: "smooth",
        el: to.hash,
      };
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.params.id) {
    store.commit("setCurrentEventID", to.params.id);
  }
  if (to.path.includes("ticket")) {
    store
      .dispatch("getCurrentToken")
      .then((token) => {
        axios.defaults.headers.common["Authorization"] = token;
        next();
      })
      .catch((err) => {
        store.dispatch("signOut").then(() => {
          console.log("signOut", err);
          next();
        });
      });
  } else {
    next();
  }
});

export default router;
