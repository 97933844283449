<template>
  <div
    class="hero padding-x"
    :style="`background-image: url(${mainImage});`"
    :class="{ 'no-event': !hasUpcomingEvent }"
  >
    <Preloader
      v-if="loading"
      :loading="loading"
      size="100px"
      class="hero__loader"
    />
    <Heading
      class="hero__no-event"
      type="h2"
      v-show="!loading && !hasUpcomingEvent"
    >
      New ArcticCon date is coming soon...</Heading
    >
    <div v-show="!loading && hasUpcomingEvent" class="hero__wrapper">
      <div class="hero__info">
        <div>
          <Heading type="h1" class="mt-2 hero__heading">
            {{ event?.name }}
          </Heading>
          <p class="hero__text">
            {{ event?.description }}
          </p>
        </div>
        <div v-show="event.start" class="hero__count mobile">
          <!--          <div class="hero__count-highlight mobile">
            {{ getTimeLeft() }}
            <span class="hero__count-unit">{{ timeLeftWord }}</span>
          </div>-->
        </div>
        <div class="hero__box-wrapper">
          <div class="hero__box">
            <h5 class="hero__box-heading">Where</h5>
            <div class="hero__box-text">
              {{ event?.venue?.name }}
              <div>{{ event?.venue?.address }}</div>
            </div>
          </div>
          <div class="hero__box">
            <h5 class="hero__box-heading">When</h5>
            <div class="hero__box-text">
              {{ confDay }}
              <div>{{ confTime }}</div>
            </div>
          </div>
        </div>

        <!--        <Button
          type="text"
          class="hero__button"
          @click="$router.push(`/${$route.params.id}/join`)"
        >
          Register
        </Button>-->
        <router-link
          style="text-decoration: none"
          :to="{
            name: 'Event',
            params: { id: currentEventID || $route.params.id },
            hash: '#about',
          }"
        >
          <Button class="hero__button" type="text"> Explore </Button>
        </router-link>
      </div>

      <div v-show="event.start" class="hero__count">
        <div>{{ getTimeLeft() - 2 }}</div>
        <div>{{ getTimeLeft() - 1 }}</div>
        <div class="hero__count-highlight">
          {{ getTimeLeft() }}
          <span class="hero__count-unit">{{ timeLeftWord }}</span>
        </div>
        <div>{{ getTimeLeft() + 1 }}</div>
        <div>{{ getTimeLeft() + 2 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import Button from "@/components/common/Button";
import Preloader from "@/components/common/Preloader";

export default {
  name: "MainBlock",
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    currentEventID: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasUpcomingEvent: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Preloader,
    Heading,
    Button,
  },
  data: () => ({
    timeLeft: null,
    timeLeftWord: "days",
  }),
  created() {
    // this.getTimeLeft();
  },
  computed: {
    mainImage() {
      return this.event.background
        ? this.event.background
        : require("@/assets/img/main_background.jpeg");
    },
    startTimeHours() {
      return this.getTime(this.event.start, "time");
    },
    endTimeHours() {
      return this.getTime(this.event.end, "time");
    },
    confDay() {
      return this.getTime(this.event.start, "day");
    },
    confTime() {
      return this.event?.start && this.event?.end
        ? `${this.startTimeHours} - ${this.endTimeHours}`
        : "";
    },
  },
  methods: {
    getTimeLeft() {
      const datesDiff = new Date(this.event.start) - Date.now();
      const toEventLeftDays = Math.floor(datesDiff / 1000 / 60 / 60 / 24);

      if (toEventLeftDays <= 0) {
        this.timeLeftWord = "days";
        return 0;
      } else if (toEventLeftDays < 1) {
        this.timeLeftWord = "day";
        return 1;
      } else {
        this.timeLeftWord = "days";
        return toEventLeftDays;
      }
    },
    getTime(dateString, modifyer) {
      const startDate = new Date(dateString);
      const options =
        modifyer === "time"
          ? {
              timeZone: "US/Alaska",
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
            }
          : {
              month: "short",
              day: "2-digit",
              year: "numeric",
            };
      return startDate.toLocaleString("en-US", options);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.hero {
  $self: &;

  height: 100vh;
  position: relative;
  padding-top: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-bottom: 5%;

  &.no-event {
    display: flex;
    margin: auto;
    align-items: center;
  }

  &__loader {
    margin-top: 50%;
  }

  &__no-event {
    color: darken($white, 12%);
    text-decoration: none;
    max-width: 700px;
    font-size: 72px;
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__heading {
    position: relative;
    overflow-wrap: break-word;
    font-size: 40px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    text-decoration: none;
    color: $white;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  &__text {
    font-size: 0.7em;
    line-height: 0.4em;
    color: #ffffff;
    text-align: center;
  }

  &__box {
    position: relative;
    margin-left: 10%;

    &:first-of-type {
      margin-bottom: 3%;
      min-width: 115px;
      /*      #{$self}__box-text {
                                                          text-transform: uppercase;
                                                        }*/
    }
  }

  &__box-heading {
    position: absolute;
    top: 31%;
    left: -22%;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.4);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  &__box-text {
    font-family: $sarabun;
    overflow: hidden;
    font-size: 18px;
    font-weight: $sarabunSemibold;
    line-height: 32px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;

    div {
      font-family: $openSans;
      font-size: 0.5em;
      font-weight: 400;
    }
  }

  &__button {
    width: 100%;
    color: $black;
    background-color: $white;
    border: none;
  }

  &__button:hover {
    color: $white;
    background-color: transparent;
    border: 4px solid $white;
  }

  &__count {
    margin-bottom: 5%;
    font-family: $sarabun;
    font-weight: 800;
    font-size: 144px;
    line-height: 140px;
    letter-spacing: 0.05em;
    color: $white;
    text-align: center;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(white, 0.4);
    user-select: none; /* Standard */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */

    div {
      padding: 3.5% 0;
    }
  }

  &__count:not(.mobile) {
    display: none;
  }

  &__count-highlight {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $white;
    position: relative;
  }

  &__count-unit {
    position: absolute;
    right: 0;
    bottom: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    text-align: right;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $white;
  }

  @media (min-width: $media-xs) {
    padding-top: 95px;

    &__text {
      font-size: 1em;
      line-height: 32px;
    }

    &__count {
      font-size: 176px;
      line-height: 160px;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 138px;

    &__wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
    }

    &__heading {
      text-align: left;
    }

    &__info {
      max-width: 323px;
    }

    &__text {
      text-align: left;
    }

    &__count {
      display: none;
    }

    &__count:not(.mobile) {
      display: block;
      margin-top: -20%;
    }

    &__box {
      &:first-of-type {
        margin-bottom: 10%;
      }
    }
    &__box-heading {
      font-size: 24px;
      line-height: 24px;
    }
    &__box-text {
      white-space: unset;
      overflow: unset;
    }
  }

  @media (min-width: $media-md) {
    &__loader {
      margin-top: 15%;
    }

    &__heading {
      font-size: 3em;
    }

    &__info {
      max-width: 385px;
    }

    &__count:not(.mobile) {
      font-size: 224px;
      line-height: 224px;
      margin-top: -30%;
    }

    &__button {
      max-width: 368px;
    }
  }

  @media (min-width: $media-lg) {
    &__info {
      max-width: 602px;
    }
    &__count {
      font-size: 280px;
      line-height: 240px;
    }
    &__box-heading {
      font-size: 26px;
      line-height: 26px;
    }
    &__box-text {
      font-size: 32px;
      line-height: 40px;
    }
  }
}
</style>
