<template>
  <footer class="footer padding-x pb-12">
    <div class="footer__breef-wrapper">
      <section class="footer__breef mb-3">
        <img
          src="@/assets/img/ac-logo.png"
          alt="ArcticCon logo"
          class="footer__logo"
        />
        <p class="footer__name">
          Alaska's Information
          <br />
          Security Conference
        </p>
      </section>
      <section class="footer__contact mb-3">
        <h3 class="footer__heading smaller-lh">Contact us</h3>
        <span class="footer__contact-info smaller-lh"> (907) 290-7660 </span>
        <a
          href="mailto:info@arctic-con.com"
          class="footer__contact-info footer__email smaller-lh"
        >
          info@arctic-con.com
        </a>
      </section>
      <div class="footer__social">
        <a
          v-for="item in socialMedia"
          :key="item.name"
          :href="item.link"
          target="_blank"
          rel="noopener noreferrer"
          class="footer__social-item"
        >
          <img :src="getSocialIcon(item.name)" :alt="`${item.name} icon`" />
        </a>
      </div>
    </div>
    <div class="footer__copy-wrapper">
      <section class="footer__policies">
        <Link
          link="/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          is-smaller
          class="footer__policy-link"
        >
          Privacy Policy
        </Link>
        <Link
          link="/terms-of-service"
          target="_blank"
          rel="noopener noreferrer"
          is-smaller
          class="footer__policy-link"
        >
          Terms of service
        </Link>
        <Link
          link="/cookie-policy"
          target="_blank"
          rel="noopener noreferrer"
          is-smaller
          class="footer__policy-link"
        >
          Cookie Policy
        </Link>
      </section>
      <p class="footer__copy smaller-lh">
        Copyright ©{{ currentYear }} All rights reserved
      </p>
    </div>
  </footer>
</template>

<script>
import Link from "@/components/common/Link";

export default {
  name: "Footer",
  props: {},
  components: {
    Link,
  },
  data() {
    return {
      socialMedia: [
        // {
        //   name: "twitter",
        //   link: "https://www.facebook.com/ArcticConAK",
        // },
        {
          name: "facebook",
          link: "https://www.facebook.com/ArcticConAK",
        },
        {
          name: "linkedin",
          link: "https://www.linkedin.com/company/arcticcon/",
        },
      ],
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    getSocialIcon(name) {
      return require(`../../assets/img/icons/social/${name}.svg`);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.footer {
  position: relative;
  padding-top: 120px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 24px;
    width: 100%;
    background-color: $blue;
  }

  &__logo {
    width: 96px;
  }

  &__heading {
    font-size: 1rem;
    color: $black;
  }

  &__contact-info {
    display: block;
  }

  &__email {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  &__social {
    padding-left: 0;
    display: flex;
  }

  &__social-item {
    display: flex;
    padding-right: 15%;
  }

  &__social-item:last-of-type {
    padding-right: 0;
  }

  &__copy {
    font-size: 0.9rem;
    margin-top: 20px;
  }

  &__policies {
    display: flex;
    flex-flow: column;
  }

  &__policy-link {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: $media-xs) {
    &__breef {
      display: flex;
    }

    &__logo {
      width: 100px;
      margin-right: 16px;
    }

    &__contact,
    &__social {
      padding-left: 112px;
    }

    &__copy-wrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-top: 42px;
      margin-bottom: 50px;
    }

    &__policies {
      text-align: right;
      flex-shrink: 0;
      margin-bottom: 0;
    }

    &__copy {
      text-align: left;
      margin-right: 96px;
      margin-top: 0;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 270px;

    &__breef-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    &__breef,
    &__contact {
      width: 50%;
    }

    &__social {
      width: 100%;
    }

    &__breef {
      flex-shrink: 0;
    }

    &__logo {
      width: 108px;
      margin-right: 28px;
    }

    &__contact {
      text-align: right;
    }

    &__social {
      justify-content: flex-end;
    }

    &__copy-wrapper {
      margin-top: 28px;
      margin-bottom: 60px;
    }

    &__contact,
    &__social {
      padding-left: 0;
    }

    &__policies {
      flex-flow: row;
      justify-content: flex-end;
    }

    &__policy-link {
      margin-right: 32px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (min-width: $media-md) {
    &__breef-wrapper {
      flex-wrap: nowrap;
    }

    &__breef,
    &__contact,
    &__social {
      width: unset;
    }

    &__contact {
      order: 3;
    }

    &__social {
      justify-items: center;
      order: 2;
      align-self: flex-end;
      padding-bottom: 8px;

      &-item {
        padding-right: 60%;
      }
    }

    &__copy-wrapper {
      margin-top: 42px;
      margin-bottom: 88px;
    }
  }
}
</style>
