const getDefaultState = () => {
  return {
    workshopTitle: "",
  };
};

const state = getDefaultState(); // initial state

const getters = {
  getWorkshopTitle: (state) => state.workshopTitle,
};

const mutations = {
  setWorkshopTitle(state, payload) {
    state.workshopTitle = payload;
  },
  resetWorkshopState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default { state, getters, mutations };
