<template>
  <article class="ticket" v-if="ticket">
    <div class="ticket__header">
      <div
        class="ticket__badge"
        :style="`background: ${getTicketBadgeColor()}`"
      >
        <svg
          width="32"
          height="22"
          viewBox="0 0 32 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28.9045 0.657227V4.30097H27.4486V2.51222H25.2648V4.30097H23.8751V2.51222H21.6913V4.30097V4.69847V6.08971H19.7722V3.63847H12.2281V6.08971H10.3089V4.69847V4.30097V2.51222H8.19129V4.30097H6.73541V2.51222H4.55158V4.30097H3.0957V0.657227H0.845703V4.30097V6.61971H3.0957H9.8457V8.40846H7.331V11.3897H10.2428V8.87221H12.2281V11.2572H14.6104V13.3109H12.2281V18.6109H14.6104V21.1284H17.3898V18.6109H19.7722V13.3109H17.3898V11.2572H19.7722V8.87221H21.7575V11.3897H24.6692V8.40846H22.1545V6.61971H28.9045H31.1545V4.30097V0.657227H28.9045Z"
            :fill="getTicketBadgeIconColor()"
          />
        </svg>
      </div>
      <div>
        <p class="ticket__title">{{ ticket.title }}</p>
        <p class="ticket__price">$ {{ ticket.price / 100 }}</p>
      </div>
    </div>

    <div class="ticket__description">
      <div>
        <div class="pb-5">{{ ticket.description }}</div>
        <dl :class="!isCheckout ? 'pb-4' : 'pb-8'">
          <dt>This pass includes:</dt>
          <dd
            v-for="feature in ticket.features"
            :key="feature.feature"
            class="ticket__feature"
          >
            {{ feature.feature }}
          </dd>
        </dl>
      </div>
      <Button
        v-if="!isCheckout"
        is-empty
        :disabled="ticket.sold_out"
        class="ticket__button"
        @click="$router.push(`/${currentEventID}/checkout/${ticket.id}`)"
      >
        get ticket
      </Button>
      <div v-else>
        <h3 v-if="ticket.sold_out" class="mb-4" style="text-align: center">
          Sold out
        </h3>
        <InputNumber
          v-else
          v-model.number="quantity"
          :name="`${ticket.id}-quantity`"
          label="Quantity"
          @input="$emit('change-quantity', quantity)"
          @change="$emit('change-quantity', quantity)"
        />
      </div>
    </div>
  </article>
</template>

<script>
import Button from "@/components/common/Button";
import InputNumber from "@/components/common/InputNumber";
import { validate } from "@/assets/js/validation";
import { mapState } from "vuex";

export default {
  name: "Ticket",
  emits: ["change-quantity"],
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
    defaultQuantity: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputNumber,
    Button,
  },
  data: () => ({
    quantity: 0,
    // rules: ["number"],
    // form: {
    //   quantity: {
    //     value: "",
    //     error: "",
    //     rules: ["required", "email"],
    //   },
    // },
  }),
  computed: {
    ...mapState(["currentEventID"]),
  },
  mounted() {
    if (this.defaultQuantity) this.quantity = 1;
  },
  methods: {
    validate,
    getTicketBadgeColor() {
      let price = this.ticket.price / 100;
      let badgeColor;
      switch (true) {
        case price <= 20: {
          badgeColor = "#F2F4F6;";
          break;
        }
        case price > 20 && price <= 40: {
          badgeColor = "#DAECFF;";
          break;
        }
        case price > 40: {
          badgeColor = "#454545;";
          break;
        }
        default: {
          badgeColor = "#F2F4F6;";
          break;
        }
      }
      return badgeColor;
    },
    getTicketBadgeIconColor() {
      let price = this.ticket.price / 100;
      let iconColor;
      switch (true) {
        case price <= 20: {
          iconColor = "#B3B2B2";
          break;
        }
        case price > 20 && price <= 40: {
          iconColor = "#91AFEA";
          break;
        }
        case price > 40: {
          iconColor = "#B3B2B2";
          break;
        }
        default: {
          iconColor = "#B3B2B2";
          break;
        }
      }
      return iconColor;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.ticket {
  $self: &;

  padding: 42px 40px 55px;
  border: 1px solid #515151;

  &__header {
    height: 20%;
    display: flex;
    justify-content: space-between;
    text-align: right;
    line-height: 32px;
    letter-spacing: 0.05em;
  }

  &__badge {
    text-align: left;
    padding: 3%;
    width: 130px;
    height: 40px;
    transform: translateX(-38%);
  }

  &__title {
    font-weight: bold;
    color: $dark-gray;
  }

  &__description {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__feature {
    margin: 0;
    padding-left: 16px;
    position: relative;

    &::before {
      content: "\2013";
      position: absolute;
      left: 0;
    }
  }

  &__button {
    width: 100%;
  }

  @media (min-width: $media-sm) {
    &__badge {
      width: 64px;
      height: 130px;
      transform: translateY(-60%);
      text-align: center;
      padding: 9% 0 0 0;
    }
  }

  @media (min-width: $media-lg) {
    &__badge {
      width: 72px;
    }
  }
}
</style>
