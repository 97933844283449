const getDefaultState = () => {
  return {
    userEmail: "",
    code: "",
    currentTicketToken: "",
  };
};

const state = getDefaultState(); // initial state

const getters = {
  getUserEmail: (state) => state.userEmail,
  getUserCode: (state) => state.code,
  getTicketToken: (state) => state.currentTicketToken,
};

const mutations = {
  setUserEmail(state, payload) {
    state.userEmail = payload;
  },
  setUserCode(state, payload) {
    state.code = payload;
  },
  setTicketToken(state, payload) {
    state.currentTicketToken = payload;
  },
  resetTicketState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default { state, getters, mutations };
