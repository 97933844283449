<template>
  <div>
    <Preloader :loading="isVillagesLoading" />
    <section
      v-if="villages.length && !isVillagesLoading"
      class="villages padding-x"
    >
      <Heading type="h2" text-align="center" class="villages__heading">
        Villages
      </Heading>
      <Carousel
        wrap-around
        :settings="carouselSettings"
        :breakpoints="carouselBreakpoints"
      >
        <Slide v-for="village in villages" :key="village.id">
          <VillageCard
            :data="village"
            :image="village.img"
            has-description
            @click="$router.push(`/${currentEventID}/village/${village.id}`)"
            class="villages__item"
          />
        </Slide>
        <template #addons>
          <Navigation v-if="villages.length > 1" />
        </template>
      </Carousel>
    </section>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import Heading from "@/components/common/Heading";
import Preloader from "@/components/common/Preloader";
import VillageCard from "@/components/common/CommonCard";
import { mapActions, mapState } from "vuex";

export default {
  name: "Villages",
  props: {},
  components: {
    Preloader,
    Heading,
    VillageCard,
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    carouselSettings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    carouselBreakpoints: {
      480: {
        itemsToShow: 2,
        snapAlign: "center",
      },
      720: {
        itemsToShow: 3,
        snapAlign: "center",
      },
    },
    villages: [],
    isVillagesLoading: false,
  }),
  computed: {
    ...mapState(["currentEventID"]),
  },
  mounted() {
    this.isVillagesLoading = true;
    this.getVillages();
  },
  methods: {
    ...mapActions(["GET"]),
    getVillages() {
      return this.GET({
        route: `/public/event/${this.currentEventID}/village`,
      })
        .then((resp) => {
          this.isVillagesLoading = false;
          // console.log("getVillages", resp.data);
          this.villages = resp.data || [];
        })
        .catch((err) => {
          console.log(err);
          this.isVillagesLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.villages {
  padding-top: 32px;
  padding-bottom: 62px;

  &__heading {
    margin-bottom: 16px;
  }

  &__item {
    cursor: pointer;
  }

  @media (min-width: $media-xs) {
    &__item {
      padding: 0 8px;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 120px;

    &__heading {
      margin-bottom: 52px;
    }
  }

  @media (min-width: $media-lg) {
    &__heading {
      margin-bottom: 82px;
    }
  }
}
</style>
