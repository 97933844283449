<template>
  <div>
    <Preloader :loading="isTicketsLoading" />
    <!-- <ComingSoon v-if="isTicketsEmpty" entity="Ticketing" /> -->
    <section v-if="tickets && tickets.length" class="tickets padding-x">
      <Heading noDecoration type="h2" text-align="center" class="mb-3">
        Tickets
      </Heading>
      <p class="tickets__text">
        ACCESS CONFERENCE BY
        <Link :link="`/${$route.params.id}/join`"
          >REDEEMING A SPONSOR PROMO CODE</Link
        >
        OR PURCHASING A TICKET
      </p>

      <div
        class="tickets__list"
        :class="{ 'tickets__list--odd': tickets.length % 2 }"
      >
        <Ticket
          v-for="ticket in tickets"
          :key="ticket.type"
          v-show="!ticket.is_sponsor"
          :ticket="ticket"
          class="tickets__ticket"
        />
      </div>
    </section>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import Ticket from "@/components/common/TicketCard";
import Link from "@/components/common/Link";
import Preloader from "@/components/common/Preloader";
import { mapActions, mapState } from "vuex";
// import ComingSoon from "../common/ComingSoon";

export default {
  name: "Tickets",
  props: {},
  components: {
    // ComingSoon,
    Preloader,
    Heading,
    Ticket,
    Link,
  },
  data: () => ({
    isTicketsLoading: false,
    isTicketsEmpty: false,
    tickets: [],
  }),
  computed: {
    ...mapState(["currentEventID"]),
    baseUrl() {
      return process.env.VUE_APP_BASE_URL.split("api/")[0];
    },
  },
  mounted() {
    this.isTicketsLoading = true;
    this.getTickets();
  },
  methods: {
    ...mapActions(["GET"]),
    getTickets() {
      return this.GET({
        route: `ticketing/public/event/${this.currentEventID}/ticket_type`,
      })
        .then((resp) => {
          // console.log("getTickets", resp.data);
          if (!resp.data) this.isTicketsEmpty = true;
          this.tickets = resp.data;
          this.isTicketsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isTicketsLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.tickets {
  $self: &;

  padding-top: 32px;

  &__text {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    letter-spacing: 0.05em;
  }

  &__text:first-of-type {
    margin-bottom: 55px;
  }

  &__list {
    display: grid;
    grid-gap: 64px 0;
  }

  @media (min-width: $media-xs) {
    &__list {
      grid-gap: 50px 16px;
      grid-template-columns: repeat(2, 1fr);

      &--odd {
        #{$self}__ticket:first-child {
          grid-column: span 2;
        }
      }
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 122px;

    &__text:first-of-type {
      margin-bottom: 60px;
    }

    &__list {
      grid-gap: 50px 24px;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

      &--odd {
        #{$self}__ticket:first-child {
          grid-column: auto;
        }
      }
    }
  }

  @media (min-width: $media-lg) {
    &__text:first-of-type {
      margin-bottom: 80px;
    }
  }
}
</style>
